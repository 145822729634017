<template>
  <v-responsive min-width="1302">
      <v-progress-circular class="loader" size='70' color="black" v-if="loader" indeterminate ></v-progress-circular>
     
    <v-app class="bgr_clr_f9f9f9">
      <div class="bgr_clr_f9f9f9">
        <div class="padd-t-8 padd-b-8 padd-l-24">
          <v-breadcrumbs :items="items">
            <template v-slot:item="{ item }">
              <v-breadcrumbs-item
                class="fsize12 clr-1867c0"
                :disabled="item.disabled"
               @click="$router.push('/customer')"
              >
                {{ item.text.toUpperCase() }}
              </v-breadcrumbs-item>
            </template>
          </v-breadcrumbs>
        </div>
        <div class="padd-t-16 padd-b-16 padd-l-24">
          <span class="fsize20 clr-000 fw-bold">
            Customer Detail</span
          >
        </div>
        <div class="margin_bottom-7rem">
          <div class="marg-t-0 padd-l-24 padd-r-24">
            <v-card class="wholeCard padd-t-24 padd-0-40 min-height_1308">
             
              <v-row class="marg-0">
                <!-- <div class="marg-t-16"> -->
                <v-col cols="3" sm="3" md="3" xs="3" class="pa-0 padd-0">
                  <div class="fsize14 clr-000 fw-bold padd-t-24">
                    Customer Details
                  </div>
                </v-col>
                <v-col cols="6" sm="6" md="6" xs="6" class="pa-0 padd-0">
                  <div class="padd-t-24">
                    <div class="w-464 h-72">
                      <div class="custom-label fsize14 clr_55585a">Name</div>

                      <input
                        type="text"
                        name=""
                        :disabled="!Disabled"
                        class="
                          w-464
                          h-40
                          border_radius
                          padd-8-16
                          border-input
                          fsize14
                          outLine_none
                        "
                        v-model="name"
                        maxlength="100"
                        placeholder="Enter your Name"
                        autocomplete="off"
                        :class="{
                          'is-invalid': submitted && this.name == '',
                        }"
                        onkeypress="return (event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123) || (event.charCode==32)"
                      />
                      <div
                        class="fsize12 text-danger marg-t-4"
                        v-if="this.submitted && this.name == ''"
                      >
                        Please Enter The Name
                      </div>
                    </div>

                    <div class="marg-t-16">
                      <div class="w-464 h-72">
                        <div class="custom-label fsize13 clr_55585a">
                          AADHAR CARD
                        </div>

                        <input
                          type="text"
                          name=""
                          :disabled="!Disabled"
                          class="
                            w-464
                            h-40
                            border_radius
                            padd-8-16
                            border-input
                            fsize14
                            outLine_none
                          "
                          v-model="aadharCard"
                          maxlength="12"
                          oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                          placeholder="Enter your AADHAR CARD"
                          autocomplete="off"
                          :class="{
                            'is-invalid': submitted && this.aadharCard == '',
                          }"
                        />
                        <div
                          class="fsize12 text-danger marg-t-4"
                          v-if="this.submitted && this.aadharCard == ''"
                        >
                          Please Enter AADHAR CARD Number
                        </div>
                      </div>
                    </div>

                    <div class="marg-t-16">
                      <div class="w-464 h-72">
                        <div class="custom-label fsize13 clr_55585a">
                          PAN CARD
                        </div>

                        <input
                          type="text"
                          name=""
                          :disabled="!Disabled"
                          class="
                            w-464
                            h-40
                            border_radius
                            padd-8-16
                            border-input
                            fsize14
                            outLine_none
                          "
                          v-model="panCard"
                        
                          maxlength="10"
                          
                          placeholder="Enter your  PAN CARD"
                          autocomplete="off"
                          :class="{
                            'is-invalid': submitted && this.panCard == '',
                          }"
                        />
                        <div
                          class="fsize12 text-danger marg-t-4"
                          v-if="this.submitted && this.panCard == ''"
                        >
                          Please Enter PAN CARD Number
                        </div>
                         <!-- <span
                            class="fsize12 text-danger"
                            v-else-if="
                              this.submitted"
                            
                          >
                            Enter valid pan nimber
                          </span> -->
                      </div>
                    </div>

                    <v-row class="marg-0 marg-t-10">
                      <!-- <div class="marg-t-16"> -->

                      <v-col
                        cols="5"
                        sm="5"
                        md="5"
                        xs="5"
                        class="pa-0 padd-0 max_w-225"
                      >
                        <div class="h-72">
                          <div class="custom-label fsize14 clr_55585a">
                            Mobile Number
                          </div>

                          <input
                            type="text"
                            name=""
                            :disabled="!Disabled"
                            class="
                              w-225
                              h-40
                              border_radius
                              padd-8-16
                              border-input
                              fsize14
                              outLine_none
                            "
                            v-model="mobileNo"
                            placeholder="Enter your Mobile Number"
                            autocomplete="off"
                            oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                            maxlength="10"
                            :class="{
                              'is-invalid': submitted && this.mobileNo == '',
                            }"
                          />
                          <div
                            class="fsize12 text-danger marg-t-4"
                            v-if="this.submitted && this.mobileNo == ''"
                          >
                            Please Enter Mobile Number
                          </div>
                        </div>
                      </v-col>
                      <v-col
                        cols="5"
                        sm="5"
                        md="5"
                        xs="5"
                        class="pa-0 padd-0 max_w-225"
                      >
                        <div class="h-72 marg-l-12 w-225">
                          <div class="custom-label fsize14 clr_55585a">
                            Whatsapp Number
                          </div>

                          <input
                            type="text"
                            name=""
                            :disabled="!Disabled"
                            class="
                              w-225
                              h-40
                              border_radius
                              padd-8-16
                              border-input
                              fsize14
                              outLine_none
                            "
                            v-model="whatsappNo"
                            maxlength="10"
                            oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                            placeholder="Enter your  Whatsapp Number"
                            autocomplete="off"
                            :class="{
                              'is-invalid': submitted && this.whatsappNo == '',
                            }"
                          />
                          <div
                            class="fsize12 text-danger marg-t-4"
                            v-if="this.submitted && this.whatsappNo == ''"
                          >
                            Please Enter Whatsapp Number
                          </div>
                        </div>
                      </v-col>

                      <v-col
                        cols="2"
                        sm="2"
                        md="2"
                        xs="2"
                        class="pa-0 padd-0 max_w-225"
                      >
                        <div v-if="Disabled">
                          <div class="marg-l-24 marg-t-20">
                            <v-btn
                              class="
                                btn_width_96
                                h-40
                                brl_clr_btn
                                fsize14
                                textTransform_None
                                bgr_clr_btn_save
                              "
                              depressed
                              plain
                              retain-focus-on-click
                              ripple
                            @click="updateCustomerData()"
                            >
                              Save
                            </v-btn>
                          </div>
                        </div>
                      </v-col>
                    </v-row>
                  </div>
                </v-col>
                <v-col cols="3" sm="3" md="3" xs="3" class="pa-0 padd-0">
                  <div v-if="!Disabled"  class="fsize14 clr-1867c0 cursorpointer padd-t-24 fright " @click="Disabled = true">
                    Edit
                  </div>
                    <div v-if="Disabled"  class="fsize14 clr-1867c0  padd-t-24 fright cursorpointer" @click='resetcustomerDetails'>
                      Cancel
                    </div>
                </v-col>
                <!-- </div> -->
              </v-row>
            
              <!-- --------------------second Line----------------------------- -->

              <div>
                <hr class="lineBelowHead marg-t-32" />

                <v-row class="marg-0">
                  <!-- <div class="marg-t-16"> -->
                  <v-col cols="3" sm="3" md="3" xs="3" class="pa-0 padd-0">
                    <div class="fsize14 clr-000 fw-bold padd-t-24">
                      Other Details
                    </div>
                  </v-col>
                  <v-col cols="6" sm="6" md="6" xs="6" class="pa-0 padd-0">
                    <div class="row marg-0 padd-t-24">
                      <div class="w-464 h-72">
                        <div class="custom-label fsize14 clr_55585a">
                          Customer Type
                        </div>

                        <v-select
                          class="fsize14 marg_top w-464"
                          :items="customerTypeItem"
                          v-model="customerType"
                          label=""
                          :disabled="!othersSaveBtn"
                          dense
                          outlined
                          :class="{
                            'is-invalid h-40':
                              submittedPersonel && this.customerType == '',
                          }"
                        ></v-select>
                        <div
                          class="fsize12 text-danger marg-t-4"
                          v-if="
                            this.submittedPersonel && this.customerType == ''
                          "
                        >
                          Please Choose Customer Type
                        </div>
                      </div>

                      <div v-if="this.customerType == 'Dealer'">
                        <div class="marg-t-16">
                          <div class="w-464 h-72">
                            <div class="custom-label fsize13 clr_55585a">
                              Dealer Name
                            </div>

                            <v-select
                              class="fsize14 marg_top w-464"
                              v-model="dealerName"
                              :items="dealerlists"
                              label=""
                              dense
                              :disabled="!othersSaveBtn"
                              outlined
                              :class="{
                                'is-invalid h-40':
                                  submittedPersonel && this.area == '',
                              }"
                            ></v-select>
                          </div>
                        </div>
                      </div>
                      <div v-if="this.customerType == 'Broker'">
                        <div class="marg-t-16">
                          <div class="w-464 h-72">
                            <div class="custom-label fsize13 clr_55585a">
                              Broker Name
                            </div>

                            <v-select
                              class="fsize14 marg_top w-464"
                              v-model="brokerName"
                              :items="brokerlists"
                              label=""
                               :disabled="!othersSaveBtn"
                              dense
                              outlined
                              :class="{
                                'is-invalid h-40':
                                  submittedPersonel && this.area == '',
                              }"
                            ></v-select>
                          </div>
                        </div>
                      </div>

                      <div class="marg-t-16">
                        <div class="w-464 h-72">
                          <div class="custom-label fsize13 clr_55585a">
                            Area
                          </div>

                          <v-select
                            class="fsize14 marg_top w-464"
                            v-model="area"
                            :items="arealistsone"
                            label=""
                            dense
                            :disabled="!othersSaveBtn"
                            value="areaName"
                            outlined
                            :class="{
                              'is-invalid h-40':
                                submittedPersonel && this.area == '',
                            }"
                          ></v-select>
                          <div
                            class="fsize12 text-danger marg-t-4"
                            v-if="this.submittedPersonel && this.area == ''"
                          >
                            Please Choose Area
                          </div>
                        </div>
                      </div>
                      <div class="marg-t-16">
                        <div class="w-464 h-72">
                          <div class="custom-label fsize13 clr_55585a">
                            Married
                          </div>

                          <v-select
                            class="fsize14 marg_top w-464"
                            :items="marriedItem"
                            v-model="married"
                            label=""
                            dense
                            outlined
                            @change="ChangeMarriedstatus()"
                             :disabled="!othersSaveBtn"
                            :class="{
                              'is-invalid h-40':
                                submittedPersonel && this.married == '',
                            }"
                          ></v-select>
                          <div
                            class="fsize12 text-danger marg-t-4"
                            v-if="this.submittedPersonel && this.married == ''"
                          >
                            Please Choose Married
                          </div>
                        </div>
                      </div>
                     <div  v-if="othersSaveBtn != 0 && married != 'Yes'" class="marg-l-12 marg-t-35">
                              <v-btn
                                class="
                                  btn_width_96
                                  h-40
                                  brl_clr_btn
                                  fsize14
                                  textTransform_None
                                  bgr_clr_btn_save
                                "
                                depressed
                                plain
                                retain-focus-on-click
                                ripple
                                @click="personaldetailsSave()"
                              >
                                Next
                              </v-btn>
                            </div>
                      <v-row
                        class="marg-0 marg-t-10"
                        v-if="spouseoption"
                      >
                        <v-col
                          cols="5"
                          sm="5"
                          md="5"
                          xs="5"
                          class="pa-0 padd-0 max_w-225"
                        >
                          <div class="h-72">
                            <div class="custom-label fsize14 clr_55585a">
                              Spouse Name
                            </div>

                            <input
                              type="text"
                              name=""
                              class="
                                w-225
                                h-40
                                border_radius
                                padd-8-16
                                border-input
                                fsize14
                                outLine_none
                              "
                              v-model="wifeName"
                              placeholder="Enter your Spouse Name"
                              autocomplete="off"
                              :class="{
                                'is-invalid h-40':
                                  submittedPersonel && this.wifeName == '',
                              }"
                            />
                            <div
                              class="fsize12 text-danger marg-t-4"
                              v-if="
                                this.submittedPersonel && this.wifeName == ''
                              "
                            >
                              Please Enter Spouse Name
                            </div>
                          </div>
                        </v-col>
                        <v-col
                          cols="5"
                          sm="5"
                          md="5"
                          xs="5"
                          class="pa-0 padd-0 max_w-225"
                        >
                          <div class="">
                            <div class="h-72 marg-l-12 w-225">
                              <div class="custom-label fsize14 clr_55585a">
                                Spouse Mob No
                              </div>

                              <input
                                type="text"
                                name=""
                                class="
                                  w-225
                                  h-40
                                  border_radius
                                  padd-8-16
                                  border-input
                                  fsize14
                                  outLine_none
                                "
                                maxlength="10"
                                v-model="wifeMobNo"
                                placeholder="Enter your Wife Mob No"
                                autocomplete="off"
                                :class="{
                                  'is-invalid h-40':
                                    submittedPersonel && this.wifeMobNo == '',
                                }"
                                oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                              />
                              <div
                                class="fsize12 text-danger marg-t-4"
                                v-if="
                                  this.submittedPersonel && this.wifeMobNo == ''
                                "
                              >
                                Please Enter Spouse Mob No
                              </div>
                            </div>
                          </div>
                        </v-col>
                          <v-col
                        cols="2"
                        sm="2"
                        md="2"
                        xs="2"
                        class="pa-0 padd-0 max_w-225"
                      >
                          <div v-if="othersSaveBtn != 0">
                          <div class="marg-l-24 marg-t-20">
                            <v-btn
                              class="
                                btn_width_96
                                h-40
                                brl_clr_btn
                                fsize14
                                textTransform_None
                                bgr_clr_btn_save
                              "
                              depressed
                              plain
                              retain-focus-on-click
                              ripple
                           @click="OtherDetails()"
                            >
                              Save
                            </v-btn>
                          </div>
                        </div> 
                        <!-- <div >
                          <div class="marg-l-24 marg-t-20">
                            <v-btn
                              class="
                                btn_width_96
                                h-40
                                brl_clr_btn
                                fsize14
                                textTransform_None
                                bgr_clr_btn_save
                              "
                              depressed
                              plain
                              retain-focus-on-click
                              ripple
                            @click="updateCustomerData()"
                            >
                              Save{{othersSaveBtn}}
                            </v-btn>
                          </div>
                        </div> -->
                      </v-col>
                      </v-row>
                       <!-- <v-btn v-if="othersSaveBtn" depressed color="primary" >
                        Save
                      </v-btn> -->
                  
                  
                    </div>
                  </v-col>
                  <v-col cols="3" sm="3" md="3" xs="3" class="pa-0 padd-0">
                    <div v-if="!othersSaveBtn" class="fsize14 clr-1867c0  padd-t-24 fright cursorpointer" @click="othersSaveBtn = true">
                      Edit
                    </div>
                     <div v-if="othersSaveBtn"  class="fsize14 clr-1867c0  padd-t-24 fright cursorpointer" @click='resetOtherDetails'>
                      Cancel
                    </div>
                  </v-col>
                  <!-- </div> -->
                </v-row>
              </div>

              <!-- ----------------------Third Line-------------------------------------------- -->

              <div>
                <!-- <hr class="lineBelowHead marg-t-32" />
                <v-row class="marg-0">
                
                  <v-col cols="3" sm="3" md="3" xs="3" class="pa-0 padd-0">
                    <div class="fsize14 clr-000 fw-bold padd-t-24">
                      Link AADHAR via DigiLocker
                    </div>
                  </v-col>
                  <v-col cols="6" sm="6" md="6" xs="6" class="pa-0 padd-0">
                    <div class="padd-b-28 padd-t-24">
                      <v-btn
                        depressed
                        plain
                        retain-focus-on-click
                        ripple
                        class="btn-clr textTransform_None bgr_clr_btn_cancel"
                        @click="digiLockerValidate()"
                      >
                        Link Aadhar
                      </v-btn>
                    </div>
                  </v-col>
                  <v-col cols="3" sm="3" md="3" xs="3" class="pa-0 padd-0">
                  </v-col>
                
                </v-row> -->

                <hr class="lineBelowHead marg-t-32" />
                <v-row class="marg-0">
                  <!-- <div class="marg-t-16"> -->
                  <v-col cols="3" sm="3" md="3" xs="3" class="pa-0 padd-0">
                    <div class="fsize14 clr-000 fw-bold padd-t-24">
                      Bank Account Details
                    </div>
                  </v-col>
                  <v-col cols="6" sm="6" md="6" xs="6" class="pa-0 padd-0">
                    <div class="padd-t-24 row marg-0">
                       <div class="w-400 h-72">
                        <span class="custom-label fsize14 clr_55585a">
                          IFSC Code
                        </span>

                        <!-- <div class="row marg-0"> -->
                        <input
                          type="text"
                          name=""
                          class="
                            w-400
                            h-40
                            border_radius
                            padd-8-16
                            border-input
                            fsize14
                            outLine_none
                          "
                          :disabled="!Bankdetails"
                          v-model="ifscCode"
                          placeholder="Enter your Name"
                          autocomplete="off"
                          :class="{
                            'is-invalid': submittedBank && this.ifscCode == '',
                          }"
                        />
                        <div
                          class="fsize12 text-danger marg-t-4"
                          v-if="this.submittedBank && this.ifscCode == ''"
                        >
                          Please Enter IFSC Code
                        </div>
                      </div>
                      <!-- </div> -->
                      <div class="marg-t-24 marg-l-10">
                        <v-btn
                          class="
                            bgr_clr_btn_save
                            textTransform_None
                            min_width_48
                            h-40
                          "
                          depressed
                          plain
                          retain-focus-on-click
                          ripple
                          @click="getifscCode()"
                        >
                          Go
                        </v-btn>
                      </div>

                      <!-- <div class="marg-t-24 marg-l-10">
                        <v-btn
                          class="
                            bgr_clr_btn_save
                            textTransform_None
                            min_width_48
                            h-40
                          "
                          depressed
                          plain
                          retain-focus-on-click
                          ripple
                          @click="getifscCode()"
                        >
                          Go
                        </v-btn>
                      </div> -->

                      <div class="marg-t-16">
                        <div class="w-464 h-72">
                          <div class="custom-label fsize13 clr_55585a">
                            Bank Name
                          </div>

                          <input
                            type="text"
                            name=""
                            :disabled="!Bankdetails"
                            class="
                              w-464
                              h-40
                              border_radius
                              padd-8-16
                              border-input
                              fsize14
                              outLine_none
                              form-control
                            "
                            v-model="bankName"
                                    autocomplete="off"
                          :class="{
                            'is-invalid': submittedBank && this.bankName == '',
                          }"
                        />
                        <div
                          class="fsize12 text-danger marg-t-4"
                          v-if="this.submittedBank && this.bankName == ''"
                        >
                          Please Enter Bank Name
                        </div>
                      </div>
                      </div>

                      <div class="marg-t-16">
                        <div class="w-464 h-72">
                          <div class="custom-label fsize13 clr_55585a">
                            Bank Address
                          </div>
                          <input
                            type="text"
                            name=""
                            :disabled="!Bankdetails"
                            class="
                              w-464
                              h-40
                              border_radius
                              padd-8-16
                              border-input
                              fsize14
                              outLine_none
                              form-control
                            "
                            v-model="bankAddress"
                            autocomplete="off"
                          :class="{
                            'is-invalid': submittedBank && this.bankAddress == '',
                          }"
                        />
                        <div
                          class="fsize12 text-danger marg-t-4"
                          v-if="this.submittedBank && this.bankAddress == ''"
                        >
                          Please Enter BankAddress
                        </div>
                      </div>
                      </div>

                      <div class="marg-t-16">
                        <div class="w-464 h-72">
                          <div class="custom-label fsize13 clr_55585a">
                            Account Number
                          </div>

                          <input
                            type="text"
                            name=""
                            :disabled="!Bankdetails"
                            class="
                              w-464
                              h-40
                              border_radius
                              padd-8-16
                              border-input
                              fsize14
                              outLine_none
                            "
                            v-model="accountNo"
                            maxlength="30"
                            placeholder="Enter your Account Number"
                            autocomplete="off"
                            :class="{
                              'is-invalid':
                                submittedBank && this.accountNo == '',
                            }"
                            oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                          />

                          <div
                            class="fsize12 text-danger marg-t-4"
                            v-if="this.submittedBank && this.accountNo == ''"
                          >
                            Please Enter Account Number
                          </div>
                        </div>
                      </div>

                      <div class="marg-t-16">
                        <div class="w-464 h-72">
                          <div class="custom-label fsize13 clr_55585a">
                            Confirm Account Number
                          </div>

                          <input
                            type="text"
                            name=""
                            :disabled="!Bankdetails"
                            class="
                              w-464
                              h-40
                              border_radius
                              padd-8-16
                              border-input
                              fsize14
                              outLine_none
                            "
                            v-model="ConfirmAccountNo"
                            maxlength="30"
                            placeholder="Enter your Confirm Account Number"
                            autocomplete="off"
                            :class="{
                              'is-invalid':
                                submittedBank && this.ConfirmAccountNo == '',
                            }"
                            oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                          />

                          <div
                            class="fsize12 text-danger marg-t-4"
                            v-if="
                              this.submittedBank && this.ConfirmAccountNo == ''
                            "
                          >
                            Please Enter Confirm Account Number
                          </div>
                          <span
                            class="fsize12 text-danger"
                            v-else-if="
                              this.submittedBank &&
                              this.accountNo != this.ConfirmAccountNo
                            "
                          >
                            Mitchmatch Account Number
                          </span>
                        </div>
                      </div>

                   <div v-if="Bankdetails">
                          <div class="marg-l-12 marg-t-34">
                            <v-btn
                              class="
                                btn_width_96
                                h-40
                                brl_clr_btn
                                fsize14
                                textTransform_None
                                bgr_clr_btn_save
                              "
                              depressed
                              plain
                              retain-focus-on-click
                              ripple
                              @click="UpdateBankdetails()"
                            >
                              Save
                            </v-btn>
                          </div>
                        </div>
                    </div>
                  </v-col>
                  <v-col cols="3" sm="3" md="3" xs="3" class="pa-0 padd-0">
                    <div v-if="!Bankdetails" class="fsize14 clr-1867c0  padd-t-24 fright cursorpointer" @click="Bankdetails = true">
                      Edit
                    </div>
                     <div v-if="Bankdetails"  class="fsize14 clr-1867c0  padd-t-24 fright cursorpointer" @click='resetBankdetails'>
                      Cancel
                    </div>
                  </v-col>
                  
                  <!-- </div> -->
                </v-row>
              </div>

              <hr class="lineBelowHead marg-t-32" />

              <!-- <div class="fsize14 clr-000"> Pettioner Detail</div> -->
            </v-card>
          </div>

          <div v-if="this.alldetailsSaveCancel == true">
            <div class="fright">
              <div class="row marg-t-20 marg-r-24">
                <div class="w-96">
                  <v-btn
                    class="
                      btn_width_96
                      h-40
                      brl_clr_btn
                      fsize14
                      textTransform_None
                      bgr_clr_btn_cancel
                    "
                    depressed
                    plain
                    retain-focus-on-click
                    ripple
                  >
                    Cancel
                  </v-btn>
                </div>
                <div class="marg-l-16 w-96">
                  <v-btn
                    class="
                      btn_width_96
                      h-40
                      brl_clr_btn
                      fsize14
                      textTransform_None
                      bgr_clr_btn_save
                    "
                    depressed
                    plain
                    retain-focus-on-click
                    ripple
                    @click="customerSave()"
                  >
                    Save
                  </v-btn>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </v-app>
  </v-responsive>
</template>

<script>
import httpService from "../js/httpservice";
import Vue from "vue";
import EventBus from "../main.js";
export default {
  data() {
    return {
      // regex : /([A-Z]){5}([0-9]){4}([A-Z]){1}$/,
      educationalItem1: ["UG", "Graduate", "PG", "Professional", "others"],
      marriedItem: ["Yes", "No"],
      spouseoption: false,
      customerTypeItem: ["Direct", "Dealer", "Broker"],
      married: "",
      name:"",
      brokerName: "",
      othersSaveBtn:false,
      Bankdetails: false,
      Disabled: false,
      items: [{text: "Customer", disabled: false, href: "customer",}, { text: "Customer Detail", disabled: true,href: "breadcrumbs_link_1",},],
      name: "",
      aadharCard: "",
      panCard: "",
      mobileNo: "",
      whatsappNo: "",
      customerType: "",
      area: "",
      ifscCode: "",
      bankName: "",
      bankAddress: "",
      accountNo: "",
      ConfirmAccountNo: "",
      wifeName: "",
      wifeMobNo: "",
      submitted: false,
      submittedPersonel: false,
      submittedBank: false,
      personalDetailsAll: false,
      linkAadharDigilocker: false,
      bankAccountDetails: false,
      userId: "",
      alldetailsSaveCancel: false,
      enable: true,
      submitDisabled: "",
      // :disabled='isDisabled'
      customerNext: true,
      personalNextbtn: false,
      bankDetailNextbtn: false,
      loader: false,
      disabled: 0,
      disabledNext: 0,
      personelNext: 0,
      areaList: "",
      arealistsone: [],
      dealerlists: [],
      brokerlists: [],
      areaItem: "",
      dealerName: "",
      brokerName: "",
      // customerDetail: []
    };
  },
  computed: {
    isDisabled: function () {
      return !this.custom;
    },
  },

  methods: {
    customerSave() {
      this.submitted = true;
      this.$router.push("/customer");
    },
        
    // change:function(e){
    //   const number = e.target.value
    //   this.isNumberValid(number);
    // },
    // isNumberValid: function(inputNumber) {
    //   this.isValid=   this.regex.test(inputNumber)
    // },


    
         /** /
      
       * Method to call update customer details
       * @author Saranraj
       * @since 16/11/2021
       * @return na
       */
    ChangeMarriedstatus() {
    if (this.married == 'Yes') {
      this.spouseoption = true
      this.hidedata = false
    } 
    else if (this.married == 'No') {
      this.spouseoption = false
      this.hidedata = true
   }
  },


    customerdetailsSave() {

      this.submitted = true;

      if (
        this.name == "" ||
        this.aadharCard == "" ||
        this.panCard == "" ||
        this.mobileNo == "" ||
        this.whatsappNo == ""
      ) {
        return false;
      }
      this.disabled = 1;
      this.disabledNext = 1;
      this.customerNext = false;
      this.personalNextbtn = true;

      /**
       * Method to Customer DetailsSave
       * @author Rajapandi
       * @since 29/10/2021
       * @updatedOn :
       * @updatedBy
       * @return na
       */

      let jsondata = {
        customer_name: this.name,
        mobile_number: this.mobileNo,
        whatsapp_no: this.whatsappNo,
        pan_card: this.panCard,
        aadhar_no: this.aadharCard,
        user_id: this.userId,
        branch_id: "1",
      };
      httpService.customerDetail(jsondata).then(
        (response) => {
          if (response.status == 200) {
            if (response.data["status"] == 1) {
              localStorage.setItem(
                "userprofile",
                JSON.stringify(response.data["result"])
              );
            //    Vue.$toast.success("Customer details updated successfully", {
            //      position: "top-right",
            //      timeout: 2381,
             
            //   // optional options Object
            // });
            } else {
            }
          }
        },
        (err) => {
          console.log(err);
        }
      );

      this.personalDetailsAll = true;
      this.enable = true;
    },
   
   
     
   resetcustomerDetails: function() {
        this.name = ''; 
        this.aadharCard = ''; 
        this.panCard = ''; 
        this.mobileNo = '';
        this.whatsappNo = '';
        this.Disabled = false
         this.submitted = false
    },


     resetOtherDetails: function () { 
        this.customerType = ''; 
        this.dealerName = ''; 
        this.brokerName = ''; 
        this.area = '';
        this.married = '';
        this.othersSaveBtn = false
        this.hidedata = true
        this.spouseoption = false
   },
    

    resetBankdetails: function(){
       this.ifscCode = '';
       this.bankName = '';
       this.bankAddress = '';
       this.accountNo = '';
       this.ConfirmAccountNo = '';
       this.Bankdetails = false
      this.submittedBank = false
    },

    getifscCode() {
      let jsondata = {
        ifsc_code: this.ifscCode,
      };

      httpService.getifscCodeBankDetail(jsondata).then(
        (response) => {
          if (response.status == 200) {
            if (response.data["status"] == 1) {
              this.bankName = response.data["result"]["BANK"];
              this.bankAddress = response.data["result"]["ADDRESS"];
            } else {
            }
          }
        },
        (err) => {
          console.log(err);
        }
      );
    },

    getAreaList() {
      httpService.getAreaList().then(
        (response) => {
          if (response.status == 200) {
            if (response.data["status"] == 1) {
              this.area = response.data["result"]["areaName"];

              this.arealistsone = [];
              var areaList = response.data.result;
              for (let item of areaList) {
                this.arealistsone.push(item.areaName);
              }
              
            } else {
            }
          }
        },
        (err) => {
          console.log(err);
        }
      );
    },

    getBrokerList() {
      httpService.getBrokerList().then(
        (response) => {
          if (response.status == 200) {
            if (response.data["status"] == 1) {
              var brokerList = response.data.result;
              for (let item of brokerList) {
                // this.brokerName = item.brokerName;
                this.brokerlists.push(item.brokerName);
              }
            } else {
            }
          }
        },
        (err) => {
          console.log(err);
          // if (err.response.status == 401) {
          //   this.openNotification({
          //     type: "danger",
          //     message: "Session expired",
          //     duration: 4000,
          //   });
          //   this.$router.push("/");
          //   localStorage.clear();
          // }
        }
      );
    },
    getDelearList() {
      httpService.getDealerList().then(
        (response) => {
          if (response.status == 200) {
            if (response.data["status"] == 1) {
              this.dealerlists = [];
              var dealerList = response.data.result;
              for (let item of dealerList) {
                // this.dealerName = item.agentName;
                this.dealerlists.push(item.agentName);
              }
            } else {
            }
          }
        },
        (err) => {
          console.log(err);
        
        }
      );
    },




    getCustomerDetails() {
      let jsondata = {
        branch_id: "1",
      };
      httpService.getcustomerAllDetail(jsondata).then(
        (response) => {
          if (response.status == 200) {
            if (response.data["status"] == 1) {
              this.customerDetail = response.data.result;
              var allgetcustomerDetail = response.data.result;
              for (let item of allgetcustomerDetail) {
                this.userid = item.user_id;
                if (item.user_id == this.userid) {
                }
              }
            } else {
              // this.openNotification({
              //   type: "danger",
              //   message: response.data["reason"],
              //   duration: 3000,
              // });
            }
          }
        },
        (err) => {
          console.log(err);
         
        }
      );
    },

    getbankDetails() {
      httpService.getBankDetail().then(
        (response) => {
          if (response.status == 200) {
            if (response.data["status"] == 1) {
            } else {
              this.openNotification({
                type: "danger",
                message: response.data["reason"],
                duration: 3000,
              });
            }
          }
        },
        (err) => {
          console.log(err);
          // if (err.response.status == 401) {
          //   this.openNotification({
          //     type: "danger",
          //     message: "Session expired",
          //     duration: 4000,
          //   });
          //   this.$router.push("/");
          //   localStorage.clear();
          // }
        }
      );
    },
    digiLockerValidate() {
      let json = {
        user_id: this.userId,
      };

      httpService.digilocker(json).then((response) => {
        if (response.status == 200) {
          if (response.data["status"] == 1) {
            var digiLockerURL = response.data.result.redirectUrl;
            window.open(response.data.result.redirectUrl, "_self");
          }
        }
      });
    },
  

    /** /
       * Method to call update customer details
       * @author Saranraj
       * @since 16/11/2021
       * @return na
       */


    updateCustomerData(){
     this.loader = true
      this.submitted = true

      if(this. customerType == '' || this.mobileNo == '' || this.whatsappNo == '' || this.panCard == '' || this.aadharCard == ''){
        return false;
      }
      let jsondata = {
         customer_name : this.name,
         mobile_number : this.mobileNo,
         whatsapp_no : this.whatsappNo,
         pan_card : this.panCard,
         aadhar_no : this.aadharCard,
         user_id : this.userId,
         branch_id : "1",
      };
      httpService.updateCustomerRecords(jsondata).then((response) => {
        console.log(response)
        if(response.status == 200){
          if(response.data['status'] == 1){
            console.log("Response", 'Updated')
             Vue.$toast.success("Customer details updated successfully", {
                 position: "top-right",
                 timeout: 2381,
             
              // optional options Object
            });
          }
        }
      }).finally(()=> {this.loader = false})
    },

      /** /
       * Method to call update Other details
       * @author Saranraj
       * @since 17/11/2021
       * @return na
       */


    OtherDetails(){
 this.loader = true
      this.submitted = true

      if(this. customerType == '' || this.mobileNo == '' || this.whatsappNo == '' || this.panCard == '' || this.aadharCard == ''){
        return false;
      }
      let jsondata = {
        user_id: this.userid,
        customer_type: this.customerType,
        address: this.area,
        marital_status: this.married,
        spouse_name: this.wifeName,
        spouse_mobile_number: this.wifeMobNo,
        branch_id: "1",
      };
      httpService.customerPersonalDetail(jsondata).then((response) => {
        console.log(response)
        if(response.status == 200){
          if(response.data['status'] == 1){
            console.log("Response", 'Updated Other Details')
             Vue.$toast.success("Other details updated successfully", {
                 position: "top-right",
                 timeout: 2381,
             
              // optional options Object
            });
          }
          else{
            console.log('failed')
          }
        }
      }).finally(()=> {this.loader = false})
    },


   /** /
       * Method to call update BankInfo details
       * @author Saranraj
       * @since 17/11/2021
       * @return na
       */

    UpdateBankdetails(){
      this.loader = true
      this.submittedBank = true

      if(this.ifscCode == '' || this.bankName == '' || this.bankAddress == '' || this.accountNo == ''|| this.ConfirmAccountNo == ''){
        return false;
      }

      let jsondata = {
        user_id: this.userid,
        bank_address: this.bankAddress,
        account_no: this.accountNo,
        ifsc_code: this.ifscCode,
        bank_name: this.bankName,
        branch_id: "1"
      }
       httpService.customerBankDetail(jsondata).then((response) =>{
         console.log(response)
         if(response.status == 200){
           if(response.data['status'] == 1){
             console.log('Response', 'Bankdetails updated')
              Vue.$toast.success("Bank details updated successfully", {
                 position: "top-right",
                 timeout: 2381,
             
              // optional options Object
            });
           }
           else{
             console.log('failed')
           }
         }
       }).finally(()=> {this.loader = false})
    }
  },

  mounted() {
    console.log(" in Customer View", this.$store.state.message);
    // ---------------Customer Detail-----------------------
    this.name = this.$store.state.message.customer_name;
    this.aadharCard = this.$store.state.message.aadhar_no;
    this.panCard = this.$store.state.message.pan_card;
    this.mobileNo = this.$store.state.message.mobile_number;
    this.whatsappNo = this.$store.state.message.whatsapp_no;
    // ---------------Personel Detail-----------------------
    this.customerType = this.$store.state.message.customer_type;
    this.married = this.$store.state.message.marital_status;
    // ---------------Personel Detail-----------------------
    this.ifscCode = this.$store.state.message.bank_ifsc_code;
    this.bankName = this.$store.state.message.bank_name;
    this.bankAddress = this.$store.state.message.address;
    this.accountNo = this.$store.state.message.bank_account_no;
    this.ConfirmAccountNo = this.$store.state.message.bank_account_no;

    if (
      localStorage.getItem("userprofile") != undefined ||
      localStorage.getItem("userprofile") != null
    ) {
      this.userId = JSON.parse(localStorage.getItem("userprofile"))["user_id"];
    }
    // this.getCustomerDetails();
    // this.getPersonalDetails();
    // this.getbankDetails();
    this.getAreaList();
    this.getBrokerList();
    this.getDelearList();
    this.getCustomerDetails();
  },
};
</script> 

<style>
/* .disableInput {
  background-color: #e9ecef;
  opacity: 1;
} */
.v-input--is-disabled:not(.v-input--is-readonly) {
  pointer-events: none;
  background: #e9ecef !important;
  height: 40px !important;
}
[disabled] {
  cursor: default;
  background: #e9ecef !important;
}
.cursorpointer {
  cursor: pointer !important;
}
</style>